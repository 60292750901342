export const articles = [
  {
    title:
      'Solving Complex Scheduling Challenges in Aviation',
    body: (
      <>
        <p className="mb-4 text-gray-700">
          From a scheduling and planning perspective, running an airline or a
          charter company is about as complicated as it gets. The industry's
          operational demands transcend merely charting flight paths; they
          require orchestrating an intricate web of activities that involve
          planes, crews, passengers, and ground staff, all while adhering to
          stringent regulatory requirements and adapting to dynamic and often
          unpredictable conditions.
        </p>

        <h3 className="text-xl font-bold mb-2 text-gray-900">
          The Complexity of Aviation Scheduling
        </h3>
        <p className="mb-4 text-gray-700">
          At the heart of aviation's operational challenges lies scheduling.
          Airlines must determine where each plane will be, when, and for how
          long. This includes coordinating:
        </p>
        <ul className="mb-4 list-disc list-inside space-y-2 text-gray-700">
          <li>
            <strong>Aircraft Positioning:</strong> Ensuring planes are at the
            right location for their next assignment.
          </li>
          <li>
            <strong>Crew Management:</strong> Assigning and relocating pilots,
            crew and maintenance staff to align with flights and operations.
          </li>
          <li>
            <strong>Ground Operations:</strong> Managing fueling, catering, and
            maintenance while adhering to airport-specific regulations (e.g.,
            refueling restrictions at certain times).
          </li>
          <li>
            <strong>Passenger Logistics:</strong> Synchronizing flights with
            passenger needs and connections.
          </li>
          <li>
            <strong>Maintenance/Parts Logistics:</strong> Ensuring correct
            components are where they need to be to avoid unnecessary delays.
          </li>
          <li>
            <strong>Regulatory Compliance:</strong> Navigating airspace
            restrictions, airport rules, and runway availability.
          </li>
        </ul>

        <p className="mb-4 text-gray-700">
          The complexity amplifies when factoring in variables like short-term
          disruptions and alterations: sudden runway closures due to weather,
          unwell crew members, or last-minute changes in passenger itineraries.
          Each of these scenarios adds to an immense search space, making
          traditional methods of planning and optimization increasingly
          inadequate.
        </p>

        <h3 className="text-xl font-bold mb-2 text-gray-900">
          Tromero’s Innovative Approach
        </h3>
        <p className="mb-4 text-gray-700">
          Tromero uses symbolic planning and generative AI to address aviation’s
          scheduling conundrum. Our approach integrates advanced technologies to
          tackle both the long-term and short-term dimensions of aviation
          planning. Here’s how:
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Expedited Knowledge Engineering
        </h4>
        <p className="mb-4 text-gray-700">
          We extract critical information from diverse sources such as internal
          documents, data lakes, common-sense business rules, and IoT sensor
          data. This knowledge is converted into symbolic formats, enabling the
          system to integrate domain-specific insights with logical structures
          that facilitate informed decision-making.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Optimization and Planning
        </h4>
        <p className="mb-4 text-gray-700">
          Using advanced algorithms, we generate optimized schedules that
          allocate resources—planes, crew, and ground staff—in the most
          efficient way possible. This step accounts for myriad constraints,
          including regulatory compliance, logistical limitations, and
          operational priorities.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Agent-Based Modelling for Robustness
        </h4>
        <p className="mb-4 text-gray-700">
          Long-term planning is evaluated through agent-based modeling, which
          simulates various scenarios to test the robustness of proposed
          schedules. This in silico simulation allows for predictive analysis,
          assessing the likelihood of success and identifying potential
          vulnerabilities before they manifest in real-world operations.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Short-Term Replanning and Constraint Updates
        </h4>
        <p className="mb-4 text-gray-700">
          Aviation operations are inherently dynamic. Tromero’s system
          continuously incorporates real-time updates, such as weather changes,
          crew availability, and equipment malfunctions. By leveraging local
          search methods, it recalculates and refines plans to deliver
          near-optimal solutions in response to evolving circumstances.
        </p>

        <p className="mb-4 text-gray-700">
          What sets Tromero apart is its use of generative AI to complement
          symbolic planning. While symbolic methods provide a structured
          framework for logical decision-making, generative AI introduces
          flexibility, enabling the system to propose novel solutions to
          unforeseen challenges in adapting the symbolic planner.
        </p>

        <p className="text-gray-700">
          In a world where aviation operations are becoming increasingly
          intricate, Tromero’s solution offers a way to turn complexity into
          opportunity, ensuring that the industry remains agile in the face of
          ever-evolving demands.
        </p>
      </>
    ),
  },

  {
    title: 'Market Analysis and Investment Signal Detection',
    body: (
      <>
        <p className="mb-4 text-gray-700">
          Investment teams face a near overwhelming task: analysing vast amounts
          of data, identifying trends, and making informed decisions to maximize
          returns while minimizing risk. The complexity of market analysis lies
          in navigating a vast web of information, including natural language
          data from financial news, reports, and social media, alongside
          numerical time series data. This intricate landscape requires
          successful analysts to uncover hidden investment or arbitrage
          opportunities, detect anomalies, and optimise portfolios from a vast
          array of quantitative and qualitative analysis.
        </p>

        <h3 className="text-xl font-bold mb-2 text-gray-900">
          The Complexity of Market Analysis
        </h3>
        <p className="mb-4 text-gray-700">
          There are any number of sources one can consult and aggregate to
          develop an investment strategy. However, from a machine learning
          perspective, there are really only two large categories:
        </p>
        <ul className="mb-4 list-disc list-inside space-y-2 text-gray-700">
          <li>
            <strong>Natural Language Data:</strong> Parsing large volumes of
            text from financial news, reports, and social media to extract
            insights on market trends, recent events that could move markets,
            general sentiment, and potential investment opportunities.
          </li>
          <li>
            <strong>Time Series Data:</strong> Analysing historical market data,
            forecasts, and trends to identify patterns and predict future market
            movements.
          </li>
        </ul>

        <p className="mb-4 text-gray-700">
          From various sources of data, a strong strategy can be built around
          three possible activities:
        </p>
        <ul className="mb-4 list-disc list-inside space-y-2 text-gray-700">
          <li>
            <strong>Arbitrage Detection:</strong> Identifying correlations and
            inefficient market mechanisms which offer an opportunity to buy
            below the market price and sell above the market price in a short
            time frame.
          </li>
          <li>
            <strong>Trend Detection:</strong> Identifying market trends based on
            historical price movements, trading volumes, and market sentiment
            indicators to predict future market directions and capitalize on
            emerging opportunities.
          </li>
          <li>
            <strong>Anomaly Detection:</strong> Identifying outliers and unusual
            market behaviour to detect potential investment opportunities or
            risks.
          </li>
          <li>
            <strong>Portfolio Optimisation:</strong> Using advanced algorithms
            to optimise investment portfolios based on predicted risk tolerance
            and investment goals.
          </li>
        </ul>

        <p className="mb-4 text-gray-700">
          The complexity amplifies when factoring in variables like market
          volatility, economic indicators, and geopolitical events. Each of
          these factors adds to an immense search space, making traditional
          methods of analysis and prediction increasingly inadequate.
        </p>

        <h3 className="text-xl font-bold mb-2 text-gray-900">
          Tromero's Approach
        </h3>
        <p className="mb-4 text-gray-700">
          Tromero uses symbolic planning, data science and generative AI to
          address the complexity of market analysis — an analyst for fund
          managers.
        </p>

        <p className="mb-4 text-gray-700">
          Is our approach smarter than a human — no. However, can it read more
          than a human can? Yes. Does it get tired? No. Does it stop for coffee?
          No. Will it outperform even the most diligent analyst in the long run?
          Perhaps. But is it cool? Yes. Yes it is.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Natural Language Processing
        </h4>
        <p className="mb-4 text-gray-700">
          We extract critical information from diverse natural language sources,
          including financial news, reports, and social media. This data is
          converted into symbolic formats, enabling the system to integrate
          domain-specific insights with logical structures that facilitate
          informed decision-making.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Time Series Analysis
        </h4>
        <p className="mb-4 text-gray-700">
          Using advanced algorithms, we analyse historical market data,
          forecasts, and trends to identify patterns and predict future market
          movements. This step accounts for myriad constraints, including market
          volatility, economic indicators, and geopolitical events.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Sentiment Analysis and Integration
        </h4>
        <p className="mb-4 text-gray-700">
          We contrast and integrate sentiment from natural language data with
          data-driven forecasts and histories to form a comprehensive view of
          market conditions. This in silico analysis allows for predictive
          modelling, assessing the likelihood of success and identifying
          potential vulnerabilities before they manifest in real-world markets.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Anomaly Detection and Portfolio Optimisation
        </h4>
        <p className="mb-4 text-gray-700">
          Our system continuously incorporates real-time updates, such as market
          news, economic indicators, and geopolitical events. By leveraging
          local search methods, it recalculates and refines investment
          strategies to deliver near-optimal solutions in response to evolving
          market conditions.
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Extraordinary!
        </h4>
        <p className="mb-4 text-gray-700">So what’s new?</p>
        <p className="mb-4 text-gray-700">
          What sets Tromero apart is the use of generative AI to generate
          possible hypotheses about potential opportunities, and then
          continually monitor real-time market data for the availability of
          them. Then, using symbolic planning and logical reasoning, it
          generates structures to test these hypotheses, and generates an idea
          bank of possible trends, strategies, and arbitrage opportunities based
          on all the available information.
        </p>

        <p className="text-gray-700">
          While symbolic methods provide a structured framework for logical and
          goal-directed decision-making, generative AI introduces flexibility,
          enabling the system to propose novel investment strategies and detect
          hidden opportunities in adapting to changing market conditions.
        </p>

        <p className="text-gray-700">
          In a world where financial markets are becoming increasingly complex,
          Tromero's solution offers a way to turn complexity into opportunity,
          ensuring that investment teams remain agile in the face of
          ever-evolving market demands.
        </p>
      </>
    ),
  },
  {
    title: 'Fleet-wide Predictive Maintenance and Logistics for Aviators',
    body: (
      <>
        <p className="mb-4 text-gray-700">
          Predictive maintenance has consistently demonstrated a strong return
          on investment (ROI). Knowing when things are likely to go wrong and
          addressing them before they break avoids costly delays, downtime, and
          potentially catastrophic failures. In aviation, where even a few
          minutes of inefficiency on a small jet can waste upwards of $600 in
          fuel, optimizing maintenance and usage is not just desirable—it’s
          essential. A thoughtful initial investment in predictive systems can
          lead to long-term savings and operational reliability.
        </p>

        <h3 className="text-xl font-bold mb-2 text-gray-900">
          The Challenges of Fleet Maintenance and Logistics
        </h3>
        <ul className="mb-4 list-disc list-inside space-y-2 text-gray-700">
          <li>
            <strong>What might go wrong, when, and where?</strong> Aviation
            fleets are intricate ecosystems where machinery is constantly
            exposed to stress, wear, and environmental factors. Engines degrade,
            hydraulics falter, and parts need replacement. Identifying which
            components are most susceptible to failure and predicting the
            timeline for these issues are perennial challenges. Maintenance
            teams need answers to these questions to stay ahead of potential
            disruptions.
          </li>
          <li>
            <strong>
              Which spare parts to keep, where to keep them, and how to balance
              stock?
            </strong>{' '}
            Efficient spare part management is critical. Stockpiling too many
            parts ties up capital and creates storage challenges, while
            inadequate inventory can lead to delays in critical maintenance.
            Optimizing stock levels and strategically positioning spares across
            the fleet’s operational geography are key to maintaining flight
            readiness without incurring unnecessary costs.
          </li>
          <li>
            <strong>
              When to reorder equipment, and how to manage lead times for
              sourcing?
            </strong>{' '}
            Replenishment planning is complicated by the variability in part
            availability. While consumables like tires are easy to source at
            most airports, specialized components such as core ailerons or
            landing gear assemblies require significant lead times. Balancing
            procurement timelines with anticipated demand is a nuanced
            challenge.
          </li>
        </ul>

        <h3 className="text-xl font-bold mb-2 text-gray-900">
          How Generative AI and Data Science Can Revolutionize Fleet Maintenance
        </h3>
        <p className="mb-4 text-gray-700">
          Tromero specializes in extracting actionable insights from siloed and
          unstructured data, providing a comprehensive approach to addressing
          these challenges. Our solution involves four key steps:
        </p>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Accessing and Unifying All Data Sources
        </h4>
        <p className="mb-4 text-gray-700">
          Information pertinent to an aircraft's health and future maintenance
          needs rarely exists in a single, structured format. Aircraft sensor
          data, handwritten maintenance logs, historical service records,
          weather forecasts, and operational schedules all hold valuable
          insights.
        </p>
        <ul className="mb-4 list-disc list-inside space-y-2 text-gray-700">
          <li>
            Tromero employs generative AI to unify and structure data from
            disparate sources, breaking down silos across an entire fleet.
          </li>
          <li>
            This includes parsing unstructured data such as handwritten
            technician notes and integrating external data like anticipated
            weather conditions or airport infrastructure capabilities.
          </li>
          <li>
            By creating a unified data ecosystem, Tromero empowers
            decision-makers with a holistic view of fleet operations and
            maintenance needs.
          </li>
        </ul>

        <h4 className="text-lg font-semibold mb-2 text-gray-800">
          Fleet-wide and Task-conditioned Predictive Maintenance
        </h4>
        <p className="mb-4 text-gray-700">
          Predictive analytics leverages historical data and real-time inputs to
          identify patterns of wear and stress that precede part failures.
        </p>
        <ul className="mb-4 list-disc list-inside space-y-2 text-gray-700">
          <li>
            Tromero combines symbolic optimizers with advanced machine learning
            models to provide actionable predictions about part longevity and
            failure likelihood.
          </li>
          <li>
            These insights allow operators to preemptively schedule maintenance
            activities, reducing downtime and avoiding costly emergency repairs.
          </li>
          <li>
            Task conditioning ensures that predictions are contextualized—for
            example, a part might degrade faster on high-altitude routes with
            extreme temperature variations compared to shorter, low-altitude
            flights.
          </li>
        </ul>
      </>
    ),
  },
];
