import { Carousel, Card } from '../V2LandingThings/apple-cards-carousel';
import { articles } from './useCaseArticles';

const UseCaseGrid = () => {
  const data = articles.map((article, index) => ({
    category: [
      'Aviation',
      'Finance, Hedge Fund',
      'Aviation',
      // 'Healthcare',
      // 'Customer Support',
      // 'Finance',
    ][index],
    title: article.title,
    src: [
      'https://images.unsplash.com/photo-1692210154575-3727a1849df0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTIyfHxhdmlhdGlvbnxlbnwwfDF8MHx8fDA%3D',
      'https://images.unsplash.com/photo-1607269910784-aafe40882991?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8ZmluYW5jZXxlbnwwfDF8MHx8fDA%3D',
      'https://images.unsplash.com/photo-1680627259725-2c7da3686b97?q=80&w=2264&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      // 'https://images.unsplash.com/photo-1584697964190-7383cbee8277?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZWR1Y2F0aW9ufGVufDB8MXwwfHx8MA%3D%3D',
      // 'https://plus.unsplash.com/premium_photo-1682689622560-171194688daf?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fHN1cGVyY29tcHV0ZXJ8ZW58MHwxfDB8fHww',
      // 'https://plus.unsplash.com/premium_photo-1698421947098-d68176a8f5b2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8aGVhbHRoY2FyZXxlbnwwfDF8MHx8fDA%3D',
      // 'https://images.unsplash.com/photo-1530541263627-4314dd7a44c2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y2FsbCUyMGNlbnRlcnxlbnwwfDF8MHx8fDA%3D',
    ][index],
    content: (
      <div className="p-4">
        <h2 className="text-lg font-bold">{article.title}</h2>
        <p className="mt-2">{article.body}</p>
      </div>
    ),
  }));

  const cards = data.map((card, index) => (
    <Card key={card.src} card={card} index={index} />
  ));

  return (
    <div className="pb-12 lg:pb-0 px-4 lg:px-0">
      <div className="text-2xl leading-7 text-zinc-600  text-left  max-w-7xl mx-auto mt-8 xl:px-10 px-0">
        We work with you to build smarter products.
      </div>
      <Carousel items={cards} />
    </div>
  );
};

export default UseCaseGrid;
