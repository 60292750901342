import React from 'react';

const UseCasesCTA = () => {
  return (
    <div className="flex  flex-col justify-end">
      <div className="mb-4 text-lg leading-8 text-gray-700">
        Tromero uses generative AI to understand your data in any format and
        transform it into a logical representation. Tromero’s logical AI then
        reasons with your data to provide insights, plans and recommendations.
      </div>
      <a
        href="https://calendar.app.google/Q8ZLru9V5tksuySH9"
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center py-2 text-sm text-white border rounded-full shadow-sm border-zinc-900 w-36 bg-zinc-900 hover:bg-zinc-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-zinc-600"
      >
        Book a call
      </a>
    </div>
  );
};

export default UseCasesCTA;
