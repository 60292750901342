import React, { useMemo } from 'react';
import { motion } from 'framer-motion';

const HeroAnimationV2 = () => {
  const nodeCount = 36; // Number of circles
  const gridSize = 6; // 6x6 grid
  const spacing = 60; // Spacing for grid layout
  const duration = 6; // Duration for one full cycle (adjust for animation speed)

  // Function to calculate grid positions
  const gridPosition = (index) => ({
    x: (index % gridSize) * spacing - (gridSize * spacing) / 2 + spacing / 2,
    y:
      Math.floor(index / gridSize) * spacing -
      (Math.floor(nodeCount / gridSize) * spacing) / 2 +
      spacing / 2,
  });

  // Pre-generate random positions for smooth transitions
  const randomPositions = useMemo(() => {
    return Array.from({ length: nodeCount }).map(() => ({
      x: Math.random() * 300 - 150, // Random x within range
      y: Math.random() * 300 - 150, // Random y within range
    }));
  }, []); // Memoize to prevent re-generation on every render

  return (
    <div className="relative flex items-center justify-center h-[400px] xl:h-[500px] xl:w-[500px]  rounded-lg w-full xl:-mt-16">
      {Array.from({ length: nodeCount }).map((_, i) => {
        const grid = gridPosition(i);
        const random = randomPositions[i];

        return (
          <motion.div
            key={i}
            initial={{ x: random.x, y: random.y }}
            animate={{
              x: [random.x, grid.x, random.x], // Smooth cycle between random → grid → random
              y: [random.y, grid.y, random.y],
            }}
            transition={{
              duration: duration,
              repeat: Infinity,
              ease: 'easeInOut',
              times: [0, 0.5, 1], // Ensures equal time spent at each keyframe
            }}
            className="absolute w-2 h-2 rounded-full bg-zinc-800 "
          />
        );
      })}
    </div>
  );
};

export default HeroAnimationV2;
