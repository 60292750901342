import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import EmailModal from './Modals/EmailModal';
import CurrencySelector from './CurrencySelector';
import FooterLogo from './FooterLogo';

//discord link changed

const Footer = () => {
  const [showEmailModal, setShowEmailModal] = useState(false);

  const location = useLocation();

  const handleToggleEmailModal = () => {
    setShowEmailModal((prevState) => !prevState);
  };

  const TinyFooter = () => {
    return (
      <>
        <ul className="flex flex-wrap text-sm sm:gap-x-10 gap-x-5 gap-y-2 text-slate-800 dark:text-slate-400">
          <li className="">
            <span className="text-sm text-slate-950 sm:text-center dark:text-slate-400">
              © {new Date().getFullYear() + ' '}
              <a href="https://Tromero.ai" className="hover:underline">
                Tromero™️
              </a>{' '}
              - All Rights Reserved
            </span>
          </li>
          <li className="flex flex-wrap mt-3 sm:mt-0 sm:flex-row gap-y-5 gap-x-10">
            <Link
              to="policy"
              className="flex-shrink-0 hover:underline underline-offset-4"
            >
              Privacy Policy
            </Link>
            <Link
              to="terms"
              className="flex-shrink-0 hover:underline underline-offset-4"
            >
              Terms
            </Link>
            <Link
              to="ai-policy"
              className="flex-shrink-0 hover:underline underline-offset-4"
            >
              Responsible AI Policy
            </Link>
            <Link
              to="payment-policy"
              className="flex-shrink-0 hover:underline underline-offset-4"
            >
              Payment Policy
            </Link>
            <Link
              to="enterprise-privacy-policy"
              className="flex-shrink-0 hover:underline underline-offset-4"
            >
              Enterprise Privacy Policy
            </Link>
          </li>
        </ul>
        <div className="pt-4">
          <CurrencySelector tight />
        </div>
      </>
    );
  };
  const GeneralFooter = ({ handleToggleEmailModal }) => {
    return (
      <>
        <hr className="my-6 border-slate-200 sm:mx-auto dark:border-slate-700 lg:my-8" />
        <div className="mx-auto max-w-[1500px]">
          <div className="md:flex md:justify-between">
            <div className=" h-[100px] w-[350px] -ml-12 sm:mt-32 mb-12 sm:mb-0">
              <a
                href="/"
                className="flex items-center max-h-[120px] overflow-hidden max-w-[380px] "
              >
                {/* <img
                  src="/footerlogo2.png"
                  className="object-cover mb-6 mr-3 scale-90"
                  alt="Tromero Logo"
                /> */}
                <span className="self-center text-2xl font-semibold zinc-50 whitespace-nowrap dark:text-zinc-50"></span>
              </a>
            </div>
            <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-[30%_15%_60%]">
              <div>
                <p className="mb-6 text-sm font-semibold uppercase text-slate-950 dark:text-zinc-50">
                  Resources
                </p>
                <ul className="text-sm text-slate-800 dark:text-slate-400">
                  {/* <li className="mb-4">
                    <Link
                      to="articles"
                      className="hover:underline underline-offset-4"
                    >
                      Articles & Learning
                    </Link>
                  </li> */}
                  {/* <li className="flex flex-wrap items-center mb-4 gap-x-2">
                    <Link
                      to="/careers"
                      className="hover:underline underline-offset-4"
                    >
                      Careers
                    </Link>
                    <span className="px-1.5 py-1 text-xs text-gray-100 bg-gray-900 rounded zinc-50 whitespace-nowrap">
                      We're Hiring
                    </span>
                  </li> */}
                  <li className="mb-4">
                    <Link
                      to="/press"
                      className="hover:underline underline-offset-4"
                    >
                      Press
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      to="/contact"
                      className="hover:underline underline-offset-4"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold uppercase text-slate-950 dark:text-zinc-50">
                  Follow us
                </h2>
                <ul className="text-sm text-slate-800 dark:text-slate-400">
                  <li className="mb-4">
                    <a
                      href="https://twitter.com/TromeroAI"
                      className="hover:underline underline-offset-4"
                    >
                      X
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://www.linkedin.com/company/tromero/about/"
                      className="hover:underline underline-offset-4"
                    >
                      LinkedIn
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/discord"
                      className="hover:underline underline-offset-4"
                    >
                      Discord
                    </a>
                  </li> */}
                </ul>
              </div>
              <div className="flex gap-4">
                <div>
                  <h2 className="mb-6 text-sm font-semibold uppercase text-slate-950 dark:text-zinc-50">
                    Legal
                  </h2>
                  <ul className="space-y-4 text-sm text-slate-800 dark:text-slate-400">
                    <li>
                      <Link
                        to="policy"
                        className="hover:underline underline-offset-4 whitespace-nowrap"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="terms"
                        className="hover:underline underline-offset-4"
                      >
                        Terms
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="ai-policy"
                        className="hover:underline underline-offset-4 whitespace-nowrap"
                      >
                        Responsible AI Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="payment-policy"
                        className="hover:underline underline-offset-4 whitespace-nowrap"
                      >
                        Payment Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="enterprise-privacy-policy"
                        className="hover:underline underline-offset-4 whitespace-nowrap"
                      >
                        Enterprise Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
                <img
                  src="/ISO_IEC 27001_2022.png"
                  alt="ISO 27001 Certified"
                  className="w-24 h-24"
                />
              </div>
            </div>
          </div>
          {/* <hr className="my-6 border-slate-200 sm:mx-auto dark:border-slate-700 lg:my-8" /> */}
          <div className="-mt-6 sm:flex sm:items-center sm:justify-between">
            {/* <div className="text-center">
              <span className="text-base font-light text-zinc-800 sm:text-center font-dmSans">
                The Open Platform for <span className='text-transparent wavy bg-clip-text'>Smarter</span> AI
              </span>
              <br />
              <span className="text-xs text-slate-950 sm:text-center dark:text-slate-400">
                © {new Date().getFullYear() + ' '}
                <a href="https://Tromero.ai" className="hover:underline">
                  Tromero™️
                </a>{' '}
                - All Rights Reserved.
              </span>
            </div> */}
            <FooterLogo />

            <div className="flex items-center mt-4 space-x-6 sm:justify-center sm:mt-0">
              <CurrencySelector tight />
              <a
                href="https://twitter.com/TromeroAI"
                className="text-slate-950 hover:text-slate-950 dark:text-zinc-50"
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 22 22"
                  aria-hidden="true"
                >
                  <path
                    fill="currentColor"
                    d="M12.186 8.672 18.743.947h-2.927l-5.005 5.9-4.44-5.9H0l7.434 9.876-6.986 8.23h2.927l5.434-6.4 4.82 6.4H20L12.186 8.672Zm-2.267 2.671L8.544 9.515 3.2 2.42h2.2l4.312 5.719 1.375 1.828 5.731 7.613h-2.2l-4.699-6.237Z"
                  />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/tromero/about/"
                className="text-slate-950 hover:text-slate-950 dark:text-zinc-50"
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 22 22"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.979 5v1.586a3.5 3.5 0 0 1 3.082-1.574C14.3 5.012 15 7.03 15 9.655V15h-3v-4.738c0-1.13-.229-2.584-1.995-2.584-1.713 0-2.005 1.23-2.005 2.5V15H5.009V5h2.97ZM3 2.487a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                    clipRule="evenodd"
                  />
                  <path d="M3 5.012H0V15h3V5.012Z" />
                </svg>
              </a>
              <a
                href="/discord"
                className="text-slate-950 hover:text-slate-950 dark:text-zinc-50"
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-zinc-50"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M19 5.6c-1.4-.7-2.8-1.1-4.2-1.3l-.5 1c-1.5-.2-3-.2-4.6 0l-.5-1c-1.4.2-2.8.6-4.1 1.3a17.4 17.4 0 0 0-3 11.6 18 18 0 0 0 5 2.5c.5-.5.8-1.1 1.1-1.7l-1.7-1c.2 0 .3-.2.4-.3a11.7 11.7 0 0 0 10.2 0l.4.3-1.7.9 1 1.7c1.9-.5 3.6-1.4 5.1-2.6.4-4-.6-8.2-3-11.5ZM8.6 14.8a2 2 0 0 1-1.8-2 2 2 0 0 1 1.8-2 2 2 0 0 1 1.8 2 2 2 0 0 1-1.8 2Zm6.6 0a2 2 0 0 1-1.8-2 2 2 0 0 1 1.8-2 2 2 0 0 1 1.8 2 2 2 0 0 1-1.8 2Z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderFooter = () => {
    switch (location.pathname) {
      case '/':
      case '/careers':
      case '/about':
      case '/press':
        return (
          <footer className="p-4 bg-zinc-50 dark:bg-gray-800 sm:p-6 font-dmSans">
            <GeneralFooter handleToggleEmailModal={handleToggleEmailModal} />
          </footer>
        );
      default:
        return (
          <footer className="p-4 bg-zinc-50 dark:bg-gray-800 sm:p-6 font-dmSans">
            <TinyFooter />
          </footer>
        );
    }
  };

  return (
    <>
      <div>{renderFooter()}</div>
    </>
  );
};

export default Footer;
