'use client';

import { useState, useEffect } from 'react';
// import { FlipWords } from './flip-words';
import UseCasesCTA from './UseCasesCTA';

const useCases = [
  'datacenters.',
  'logistics.',
  'finance.',
  'aviation.',
  'healthcare.',
  'retail.',
  'education.',
];

const UseCase = () => {
  const [, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % useCases.length);
    }, 3000); // Change item every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-4">
        <span className="border rounded-full px-4 py-1 shadow-sm bg-gradient-to-tr from-zinc-200 via-white to-zinc-200 border-zinc-300 font-normal text-gray-700 uppercase text-xs w-fit">
          built for your use case
        </span>
        <div className="my-2  text-left font-dmSans font-normal xl:font-light text-4xl xl:text-7xl text-gray-900">
          {/* <span className="bg-clip-text wavyslow text-transparent">Smarter</span>{' '} */}
          Transform work
          <div className=""></div>with{' '}
          <span className="italic bg-clip-text text-transparent wavyextraslow pr-2">
            logical AI
          </span>
          {/* <FlipWords words={useCases} /> */}
        </div>
        
      </div>
      <UseCasesCTA />
    </div>
  );
};

export default UseCase;
