import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'flowbite-react';
import { Dialog, Disclosure } from '@headlessui/react';
import {
  Bars3Icon,
  FingerPrintIcon,
  XMarkIcon,
  BriefcaseIcon,
  RocketLaunchIcon,
  QuestionMarkCircleIcon,
  MegaphoneIcon,
  EnvelopeIcon,
  CpuChipIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

import EmailModal from '../Components/Modals/EmailModal';
import { useUser } from '../UserContext';
import CustomLink from '../utils/CustomLink';
import { hasUserInLocalStorage } from '../utils/userUtils';

//-----------------

//-----------------

const companyItems = [
  {
    name: 'About Us',
    description:
      'Learn more about our values and aim to revolutionizing the industry',
    href: '/about',
    icon: FingerPrintIcon,
  },
  {
    name: 'Careers',
    description:
      'Explore exciting opportunities and join us in shaping the future',
    href: '/careers',
    icon: BriefcaseIcon,
  },
  {
    name: 'FAQs',
    description: 'Answers to common questions and inquiries about our platform',
    href: '/faq',
    icon: QuestionMarkCircleIcon,
  },
  {
    name: 'Press',
    description: 'Find out what the media is saying about us',
    href: '/press',
    icon: MegaphoneIcon,
  },
];

const contactItems = [
  {
    name: 'Provide GPUs',
    description: 'Ready to join our network? Contact us to get started.',
    href: '/providersignup',
    icon: CpuChipIcon,
  },
  {
    name: 'Contact Us',
    description: 'General Inquiries, Feedback, and Technical Support',
    href: '/contact',
    icon: EnvelopeIcon,
  },
  {
    name: 'Partner With Us',
    description:
      'For Startups, Accelerators, Academic Institutions, and Student Unions',
    href: '/partner',
    icon: RocketLaunchIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SmallNavbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [, setIsNavOpen] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [toggleView] = useState(false);

  const { user, logout, setShowSignInModal, setUser } = useUser();
  const userEmail = localStorage.getItem('email') || '';
  const navigate = useNavigate();

  const handleLogin = () => {
    setShowSignInModal(true);
  };

  const handleNavOptionClick = (path) => {
    setIsNavOpen(false);
    setMobileMenuOpen(false);
    navigate(path);
  };

  const handleSignOut = () => {
    logout();
    localStorage.removeItem('darkMode');
    localStorage.removeItem('logoutTime');
    localStorage.removeItem('selectedRental');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('email');
    localStorage.clear();
    setIsNavOpen(false);
    navigate('/');
  };

  const handleToggleEmailModal = () => {
    setShowEmailModal((prevState) => !prevState);
  };

  useEffect(() => {
    const updateUserFromLocalStorage = async () => {
      await hasUserInLocalStorage(setUser);
    };
    updateUserFromLocalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full bg-zinc-50">
      <div className='p-4'><img src="/tromero_bright.png" className="w-auto h-[50px] " alt="Logo" />
    </div></div>

    // <header className="relative z-10 bg-zinc-50 isolate dark:bg-transparent dark:text-gray-200 font-dmSans">
    //   <nav
    //     className="flex items-center justify-between p-6 mx-auto max-w-7xl lg:px-8"
    //     aria-label="Global"
    //   >
    //     <div className="flex lg:flex-1 dark:hidden">
    //       <Link to="/" className="-m-1.5 p-1.5">
    //         <span className="sr-only">Tromero</span>
    //         <img
    //           src="/tromero_bright.png"
    //           className="w-auto h-[50px]"
    //           alt="Logo"
    //         />
    //       </Link>
    //     </div>
    //     <div className="hidden lg:flex-1 dark:flex">
    //       <Link to="/" className="-m-1.5 p-1.5">
    //         <span className="sr-only">Tromero</span>
    //         <img
    //           src="/tromero_bright.png"
    //           className="w-auto h-[45px] -mt-4 "
    //           alt="Logo"
    //         />
    //       </Link>
    //     </div>
    //     <div className="flex lg:hidden">
    //       <button
    //         type="button"
    //         className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
    //         onClick={() => setMobileMenuOpen(true)}
    //       >
    //         <span className="sr-only">Open main menu</span>
    //         <Bars3Icon className="w-6 h-6" aria-hidden="true" />
    //       </button>
    //     </div>

    //     <div className="hidden lg:flex lg:flex-1 lg:justify-end">
    //       <div className="flex items-center justify-center w-24 h-12">
    //         {user ? (
    //           <Dropdown
    //             arrowIcon={false}
    //             inline
    //             label={
    //               <div className="flex items-center justify-center w-10 h-10 text-gray-100 bg-gray-900 rounded-full shadow-lg">
    //                 {user.email ? (
    //                   user.email[0].toUpperCase()
    //                 ) : (
    //                   <svg
    //                     className="w-4 h-4 text-gray-200 dark:text-zinc-50"
    //                     aria-hidden="true"
    //                     xmlns="http://www.w3.org/2000/svg"
    //                     fill="none"
    //                     viewBox="0 0 14 18"
    //                   >
    //                     <path
    //                       stroke="currentColor"
    //                       strokeLinecap="round"
    //                       strokeLinejoin="round"
    //                       strokeWidth="2"
    //                       d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
    //                     />
    //                   </svg>
    //                 )}
    //               </div>
    //             }
    //           >
    //             <Dropdown.Header>
    //               <span className="block text-sm">{user.name}</span>
    //               <span className="block text-sm font-medium truncate">
    //                 {user.email === 'bobbobingtonabc@gmail.com'
    //                   ? 'Bob'
    //                   : user.email}
    //               </span>
    //             </Dropdown.Header>
    //             <Link onClick={handleToggleEmailModal}>
    //               <Dropdown.Item className="text-indigo-500">
    //                 Provide Feedback
    //               </Dropdown.Item>
    //             </Link>
    //             {!toggleView && (
    //               <>
    //                 <CustomLink to="/tailor">
    //                   <Dropdown.Item>Dashboard</Dropdown.Item>
    //                 </CustomLink>
    //               </>
    //             )}
    //             {toggleView && (
    //               <>
    //                 <CustomLink to="/providerdashboard">
    //                   <Dropdown.Item>Dashboard</Dropdown.Item>
    //                 </CustomLink>
    //               </>
    //             )}

    //             <CustomLink to="/tailor/settings">
    //               <Dropdown.Item>Settings</Dropdown.Item>
    //             </CustomLink>
    //             {user.is_admin && (
    //               <>
    //                 <CustomLink to="/admin">
    //                   <Dropdown.Item>Admin Dashboard</Dropdown.Item>
    //                 </CustomLink>
    //               </>
    //             )}

    //             <Dropdown.Divider />
    //             <Dropdown.Item onClick={handleSignOut}>Sign out</Dropdown.Item>
    //           </Dropdown>
    //         ) : (
    //           <>
    //             <button
    //               onClick={handleLogin}
    //               aria-current="page"
    //               className="px-2 py-1 text-sm text-gray-100 bg-gray-900 rounded-md shadow-lg zinc-50space-nowrap"
    //             >
    //               Log in
    //             </button>
    //           </>
    //         )}
    //       </div>
    //     </div>
    //   </nav>
    //   <Dialog
    //     as="div"
    //     className="lg:hidden"
    //     open={mobileMenuOpen}
    //     onClose={setMobileMenuOpen}
    //   >
    //     <div className="fixed inset-0 z-10" />
    //     <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 overflow-y-auto bg-zinc-50 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 dark:bg-gray-900">
    //       <div className="flex items-center justify-between">
    //         <Link
    //           to="/"
    //           className="-m-1.5 p-1.5 focus:outline-none hidden dark:block"
    //         >
    //           <span className="sr-only">Tromero</span>
    //           <img
    //             src="/tromero_bright.png"
    //             className="w-auto h-[35px]  focus:outline-none"
    //             alt="Logo"
    //           />
    //         </Link>
    //         <Link
    //           to="/"
    //           className="-m-1.5 p-1.5 focus:outline-none block dark:hidden"
    //         >
    //           <span className="sr-only">Tromero</span>
    //           <img
    //             src="/tromero_bright.png"
    //             className="w-auto h-[35px] -mt-4  focus:outline-none block sm:hidden"
    //             alt="Logo"
    //           />
    //         </Link>
    //         <button
    //           type="button"
    //           className="-m-2.5 rounded-md p-2.5 text-gray-700 dark:text-gray-300"
    //           onClick={() => setMobileMenuOpen(false)}
    //         >
    //           <span className="sr-only">Close menu</span>
    //           <XMarkIcon className="w-6 h-6" aria-hidden="true" />
    //         </button>
    //       </div>
    //       <div className="flow-root mt-6">
    //         <div className="-my-6 divide-y divide-gray-500/10">
    //           <div className="py-6 space-y-2">
    //             <Link
    //               to="/models"
    //               onClick={() => handleNavOptionClick()}
    //               className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //             >
    //               Models
    //             </Link>
    //             <Link
    //               to="/pricing"
    //               onClick={() => handleNavOptionClick()}
    //               className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //             >
    //               Pricing
    //             </Link>
    //             <Link
    //               to="https://docs.tromero.ai"
    //               onClick={() => handleNavOptionClick()}
    //               className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //               target="_blank"
    //             >
    //               Docs
    //             </Link>
    //             <Disclosure as="div" className="-mx-3 ">
    //               {({ open }) => (
    //                 <>
    //                   <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base text-base leading-7 text-zinc-900 font-dmSans">
    //                     Company
    //                     <ChevronDownIcon
    //                       className={classNames(
    //                         open ? 'rotate-180' : '',
    //                         'h-5 w-5 flex-none',
    //                       )}
    //                       aria-hidden="true"
    //                     />
    //                   </Disclosure.Button>
    //                   <Disclosure.Panel className="mt-2 space-y-2 ">
    //                     {companyItems.map((item) => (
    //                       <Disclosure.Button
    //                         key={item.name}
    //                         as="a"
    //                         href={item.href}
    //                         className="block py-2 pl-6 pr-3 text-sm text-base leading-7 text-zinc-900 font-dmSans"
    //                       >
    //                         {item.name}
    //                       </Disclosure.Button>
    //                     ))}
    //                   </Disclosure.Panel>
    //                 </>
    //               )}
    //             </Disclosure>
    //             <Link
    //               to="/articles"
    //               onClick={() => handleNavOptionClick()}
    //               className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //             >
    //               Articles & Learning
    //             </Link>

    //             <Disclosure as="div" className="-mx-3 ">
    //               {({ open }) => (
    //                 <>
    //                   <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base leading-7 text-zinc-900 font-dmSans">
    //                     Contact
    //                     <ChevronDownIcon
    //                       className={classNames(
    //                         open ? 'rotate-180' : '',
    //                         'h-5 w-5 flex-none',
    //                       )}
    //                       aria-hidden="true"
    //                     />
    //                   </Disclosure.Button>
    //                   <Disclosure.Panel className="mt-2 space-y-2 ">
    //                     {contactItems.map((item) => (
    //                       <Disclosure.Button
    //                         key={item.name}
    //                         as="a"
    //                         href={item.href}
    //                         className="block py-2 pl-6 pr-3 text-sm text-base leading-7 text-zinc-900 font-dmSans"
    //                       >
    //                         {item.name}
    //                       </Disclosure.Button>
    //                     ))}
    //                   </Disclosure.Panel>
    //                 </>
    //               )}
    //             </Disclosure>
    //           </div>
    //           <div className="py-6">
    //             <div className="flex items-center ">
    //               {user ? (
    //                 <div className="flex flex-col items-center">
    //                   <div className="mt-2 ">
    //                     <div className="flex items-center block px-3 py-2 -mx-3 text-base leading-7 text-zinc-500 font-dmSans whitespace-nowrap">
    //                       {' '}
    //                       <svg
    //                         xmlns="http://www.w3.org/2000/svg"
    //                         fill="none"
    //                         viewBox="0 0 24 24"
    //                         strokeWidth={1.5}
    //                         stroke="currentColor"
    //                         className="mr-2 size-5"
    //                       >
    //                         <path
    //                           strokeLinecap="round"
    //                           strokeLinejoin="round"
    //                           d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    //                         />
    //                       </svg>
    //                       {user.email === 'bobbobingtonabc@gmail.com'
    //                         ? 'Bob'
    //                         : user.email}
    //                     </div>
    //                     <Link
    //                       onClick={handleToggleEmailModal}
    //                       className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //                     >
    //                       Provide Feedback
    //                     </Link>
    //                     {!toggleView && (
    //                       <>
    //                         <Link
    //                           to="/tailor"
    //                           onClick={() => handleNavOptionClick()}
    //                           className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //                         >
    //                           Dashboard
    //                         </Link>
    //                       </>
    //                     )}
    //                     <Link
    //                       to="/tailor/settings"
    //                       onClick={() => handleNavOptionClick()}
    //                       className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //                     >
    //                       Settings
    //                     </Link>
    //                     {user.is_admin && (
    //                       <Link
    //                         to="/admin"
    //                         onClick={() => handleNavOptionClick()}
    //                         className="block px-3 py-2 -mx-3 text-base leading-7 text-zinc-900 font-dmSans"
    //                       >
    //                         Admin Dashboard
    //                       </Link>
    //                     )}
    //                     <button
    //                       onClick={handleSignOut}
    //                       className="block px-3 py-2 -mx-3 text-base leading-7 text-indigo-600 font-dmSans"
    //                     >
    //                       Sign out
    //                     </button>
    //                   </div>
    //                 </div>
    //               ) : (
    //                 <div className="flex flex-col justify-center w-full">
    //                   <button
    //                     onClick={handleLogin}
    //                     aria-current="page"
    //                     className="w-full h-10 mt-4 text-gray-100 bg-gray-900 rounded-md shadow-lg"
    //                   >
    //                     Log in
    //                   </button>
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </Dialog.Panel>
    //   </Dialog>
    //   <EmailModal
    //     userEmail={userEmail}
    //     showModal={showEmailModal}
    //     onClose={handleToggleEmailModal}
    //     alternativeMessage="Do you want to provide compute? Want to send us feedback? Need more information about our service? Let us know and we'll get back to you as soon as possible."
    //   />
    // </header>
  );
};

export default SmallNavbar;
